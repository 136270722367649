/* spinner */
.containerSpinner {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  margin: 0;
  padding: 0;
}

.searchWrapper,
.searchWrapperFixed {
  display: grid;
  grid-template-columns: 1fr 1fr 84px;
  grid-column-gap: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.searchWrapperFixed {
  position: fixed;
  top: 0;
  width: 100vw;
  margin-left: -165px;
  padding: 23px 164px;
  background: #f5f5f5;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.moveTop {
  z-index: 1;
}
.inputSearchContainer {
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 11px 16px;
  width: 100%;
  height: 42px;
  align-items: center;
}
.inputSearchContainer input {
  border: none !important;
}
.inputSearchContainer img {
  aspect-ratio: 1;
  width: 18px;
  cursor: pointer;
}
.inputBox,
.inputBox:focus {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
.suggestContainer {
  margin-top: 12px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  padding: 0 18.25px;
  max-width: 100%;
}
.suggestContainer span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;

  margin-left: 18px;
  cursor: pointer;
  /* max-width: 40%; */
}
.suggestionText {
  font-weight: normal;
  letter-spacing: 0.04em;
  color: #4b4a4b;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 1px;
}
.suggestionText:hover span {
  color: #42a391;
}
.suggestionText img {
  width: 13.5px;
  mix-blend-mode: luminosity;
  opacity: 0.5;
}
.suggestionText:hover img {
  mix-blend-mode: unset;
  opacity: 1;
}
.suggestionText div {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
}
.fullWidth {
  width: 100%;
}
.fixedAtTop {
  transform: none !important;
  z-index: 1;
}
/* Smartphones, iPhone, portrait 480x320 phones */
@media (max-width: 481px) {
  .searchWrapper {
    grid-template-columns: 100%;
    grid-row-gap: 22px;
  }
  .suggestContainer {
    margin-top: 0;
    text-align: left;
  }
  .categoryItem {
    padding: 6px 20px;
    display: flex;
    align-items: center;
  }
  .categoryImage {
    width: 69px !important;
    height: 52px !important;
    position: relative !important;
  }
  .categoryImage img {
    border-radius: 12px;
  }
  .categoryText,
  .selectedCategoryText {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: Mulish, sans-serif;
    letter-spacing: 0.2px;
    margin-left: 12px;
    color: #000000;
  }
  .selectedCategoryText {
    color: #42a391;
  }
  .locationItem {
    box-shadow: none !important;
    border-bottom: 1px solid #efefef;
  }
  .inputBox,
  .inputBox::placeholder {
    font-size: 14px;
  }
  .inputBox {
    color: black;
  }
  .inputBox:focus {
    font-size: 16px;
  }
  .searchWrapperFixed {
    width: 100vw;
    margin-left: -20px;
    padding: 9px 20px;
    grid-template-columns: 48% 48%;
  }
  .inputSearchContainer {
    padding: 0 14px;
    border: 1px solid #efefef;
  }
  .spinner {
    width: 70px;
    height: 70px;
  }
}
