.container {
  min-height: 100vh;
}
.header {
  height: 88px;
  width: 100%;
  display: flex;
  padding: 0 165px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}
.logoHeader {
  width: 88px;
  object-fit: contain;
  cursor: pointer;
}
.landingBackgroundImg,
.landingBackgroundWrapper {
  width: 100%;
  position: relative;
}
.landingBackgroundWrapper {
  margin-bottom: 80px;
}
.textLandingContainer {
  position: absolute;
  top: 30%;
  left: 164px;
  width: calc(57.5% - 164px);
}
.landingHeaderText,
.landingSubtext {
  font-family: Mulish, sans-serif;
  font-style: normal;
  color: #ffffff;
}
.landingHeaderText {
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0.2px;
  margin-bottom: 32px;
}
.landingSubtext {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
}
.childrenLandingWrapper {
  margin: 52px 0 0;
}
/* Smartphones, iPhone, portrait 480x320 phones */
@media (max-width: 481px) {
  .header {
    padding: 0 20px;
    height: 54px;
  }
  .logoHeader {
    width: 80px;
  }
  .textLandingContainer {
    width: calc(100% - 40px);
    left: 20px;
    top: 30px;
  }
  .landingHeaderText {
    font-size: 24px;
    line-height: 56px;
    text-align: center;
    margin: 0;
  }
  .landingSubtext {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
  .childrenLandingWrapper {
    margin: 29px 0 0;
  }
  .landingBackgroundWrapper {
    margin-bottom: 24px;
  }
  .directoryIconMob {
    width: 100%;
    height: 128px;
    margin: auto auto 5px;
  }
  .landingBackgroundImg {
    max-height: 506px;
    width: 100%;
    object-fit: cover;
  }
}
