.sheet {
  z-index: 100;
  position: fixed;
  left: 0;
  height: calc(100vh + 100px);
  width: 100%;
  border-radius: 12px 12px 0px;
  background: #fff;
  touch-action: none;
}

.sheet > div {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-transform: capitalize;
  flex-direction: column;
}
.titleSwipe {
  display: flex;
}
.titleSwipe img {
  position: absolute;
  right: 16px;
  top: 16px;
}
.titleSwipe span {
  text-align: center;
  letter-spacing: 0.02em;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}
.itemContainer {
  justify-content: flex-start !important;
  overflow: scroll;
  display: block !important;
  padding: 0 !important;
}
.backdrop {
  z-index: 1 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
